import { useStaticQuery, graphql } from 'gatsby'
import { SaveBrandsQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'

export type ISaveBrand = NonNullable<
  Unpacked<SaveBrandsQuery['allSaveBrand']['nodes']>
>

const useSaveBrandsQuery = (): ISaveBrand[] => {
  const { allSaveBrand }: SaveBrandsQuery = useStaticQuery(
    graphql`
      query SaveBrands {
        allSaveBrand(sort: { fields: [weigth, name], order: [DESC, ASC] }) {
          nodes {
            id
            imgUrl
            name
            imageFile {
              publicURL
            }
          }
        }
      }
    `
  )
  return allSaveBrand.nodes
}

export default useSaveBrandsQuery
